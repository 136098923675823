<template>
  <v-container
    id="admin-view"
    fluid
  >
    <v-row
      v-if="showDiagrams"
      justify="center"
    >
      <v-col
        cols="12"
        lg="6"
      >
        <v-img
          contain
          class="diagram-hover"
          src="~../assets/diagrams/fiserv_chandler_chiller_loop.png"
          @click="
            fullscreenDiagramDialog = true
            dialogImgSrc = require('../assets/diagrams/fiserv_chandler_chiller_loop.png')
          "
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-img
          contain
          class="diagram-hover"
          :src="require('../assets/diagrams/fiserv_chandler_oneline.png')"
          @click="
            fullscreenDiagramDialog = true
            dialogImgSrc = require('../assets/diagrams/fiserv_chandler_oneline.png')
          "
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="6"
      >
        <v-alert
          type="info"
        >
          There are currently no diagrams for this site
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-model="fullscreenDiagramDialog"
      fullscreen
      style="position: relative"
    >
      <v-sheet
        style="min-height: 100vh; overflow: hidden; background: #EEEEEE"
        class="d-flex align-center justify-center flex-wrap"
      >
        <v-btn
          color="grey darken-1"
          class="ma-6"
          text
          style="position: absolute; z-index: 2; top: 0; left: 0;"
          @click="fullscreenDiagramDialog = false"
        >
          <v-icon>
            mdi-close-thick
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="ma-6"
          fab
          style="position: absolute; z-index: 2; top: 8vh; left: 8px;"
          @click="zoomEnabled = !zoomEnabled"
        >
          <v-icon v-if="zoomEnabled">
            mdi-magnify-close
          </v-icon>
          <v-icon v-else>
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-row
          justify="center"
        >
          <v-col
            cols="10"
          >
            <figure
              class="zoom"
              :style="`background-image: url(${dialogImgSrc});`"
              @mousemove="zoom"
            >
              <img
                :class="`${zoomEnabled ? 'zoom-enabled' : ''}`"
                :src="dialogImgSrc"
                :style="`opacity: ${zoomEnabled ? '' : '0 !important!' };`"
              >
            </figure>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    name: 'Diagrams',
    data: () => ({
      showDiagrams: false,
      fullscreenDiagramDialog: false,
      dialogImgSrc: '',
      zoomEnabled: false,
    }),
    created () {
      if (this.$route.params.site === 'Chandler') {
        this.showDiagrams = true
      }
    },
    methods: {
      zoom (e) {
        if (!this.zoomEnabled) return
        const zoomer = e.currentTarget
        let offsetY
        let offsetX
        e.offsetX ? offsetX = e.offsetX : e.touches ? offsetX = e.touches[0].pageX : offsetX = 0
        e.offsetY ? offsetY = e.offsetY : e.touches ? offsetY = e.touches[0].pageY : offsetY = 0
        const x = offsetX / zoomer.offsetWidth * 100
        const y = offsetY / zoomer.offsetHeight * 100
        zoomer.style.backgroundPosition = x + '% ' + y + '%'
      },
    },
  }
</script>
<style scoped>
.diagram-hover:hover {
    cursor: pointer;
    box-shadow: 0 0 50px #ccc;
}
figure.zoom .zoom-enabled:hover {
    opacity: 0;
    cursor: crosshair;
}
figure.zoom {
  background-position: 200% 200%;
  background-size: 200%;
  position: relative;
  overflow: hidden;
}
figure.zoom img {
    transition: opacity .5s;
    display: block;
    width: 100%;
}
</style>
